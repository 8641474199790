import React, { useState } from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Button } from "components/anti/buttons/buttons"
import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"
import { Link } from "components/anti/link/link"

import appStore from "assets/img/btn-apple.svg"
import playStore from "assets/img/btn-google.svg"
import comingSoon from "assets/img/img-btn-comingsoon.svg"

export const Features = ({ data, className }) => {
  const [trigger, anim] = useScrollAnim()

  const [imageShow, setImageShow] = useState(0)

  const changeImage = image => {
    setImageShow(image)
  }

  return (
    <section className={`py-main sc-features ${className || ""}`} ref={trigger}>
      <div className="container">
        {/* Desktop */}
        <div className="d-md-block d-none">
          <div className="row">
            <div className="col-md-6 col-image">
              <div className="circle" />
              <div className="image-wrapper">
                {data?.features?.map((img, i) => {
                  const realImg = img?.image?.sourceUrl
                  return (
                    <img
                      src={realImg}
                      className={`img-features img-fluid ${
                        i === imageShow ? "" : "hide"
                      }`}
                      alt="img"
                      key={i}
                    />
                  )
                })}
              </div>
            </div>
            <div className="col-md-6 col-text">
              <h2 className={`${anim(2)} caption`}>{data.label}</h2>
              {/* prettier-ignore  */}
              <ul>
                {data.features.map((data, i) => {
                  const active = imageShow === i ? true : false
                  return (
                    <li key={i} className={`${anim(3 + i)} mb-4 ${active ? "active" : ""}`} onMouseEnter={() => changeImage(i)}>
                      <p className="mb-0" >
                        {data.text}
                      </p>
                      {!data.downloadButton && (
                        <Button variant="link" className="text-left" link={data?.buttons?.url}>
                          {data?.buttons?.text}
                        </Button>
                      )}
                      {data.downloadButton && (
                        // <div className="w-250px mt-3">
                        //   <img
                        //     src={comingSoon}
                        //     className="img-fluid"
                        //     alt="App Store"
                        //   />
                        // </div>
                        <div className="btn-group d-flex mt-3">
                          <Link to="https://apps.apple.com/us/app/dompet-aman/id1588720217">
                            <img
                              src={appStore}
                              className="img-fluid mr-2"
                              alt="App Store"
                            />
                          </Link>
                          <Link to="https://play.google.com/store/apps/details?id=com.dompetaman">
                            <img
                              src={playStore}
                              className="img-fluid ml-2"
                              alt="Play Store"
                            />
                          </Link>
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        {/* Mobile */}
        <div className="d-block d-md-none">
          <div className="circle" />
          <Slider
            noGutter
            visibleInitial={false}
            visibleLgDown={false}
            visibleMdDown={false}
            visibleSmDown={true}
            arrowsInitial={false}
            arrowsXl={false}
            arrowsMdDown={false}
            arrowsSmDown={false}
            showInitial={1}
            showXlDown={1}
            showLgDown={1}
            showMdDown={1}
            showSmDown={1}
            className="slider-features"
          >
            {data.features.map((feature, i) => {
              return (
                <Card
                  key={i}
                  noLink
                  variant="boxless"
                  img={feature?.image?.sourceUrl}
                  imgRatio="img-fluid"
                  imgHeight="h-450px"
                  className="text-center card-features"
                >
                  <div className="dot-wrapper mb-4">
                    <div className="dot-slider" />
                  </div>
                  {/* <h2 className="caption">{data.label}</h2> */}
                  <p className="mb-0">{feature.text}</p>
                  {!feature.downloadButton && (
                    <Button variant="link" link={feature.buttons.url}>
                      {feature.buttons.text}
                    </Button>
                  )}
                  {feature.downloadButton && (
                    <div className="btn-group d-flex mt-3">
                      <Link to="https://apps.apple.com/us/app/dompet-aman/id1588720217">
                        <img
                          src={appStore}
                          className="img-fluid mr-2"
                          alt="App Store"
                        />
                      </Link>
                      <Link to="https://play.google.com/store/apps/details?id=com.dompetaman">
                        <img
                          src={playStore}
                          className="img-fluid ml-2"
                          alt="Play Store"
                        />
                      </Link>
                    </div>
                    // <div className="btn-group d-flex justify-content-center mt-3">
                    //   <Link className="mx-2">
                    //     <img
                    //       src={appStore}
                    //       className="img-fluid"
                    //       alt="App Store"
                    //     />
                    //   </Link>
                    //   <Link>
                    //     <img
                    //       src={playStore}
                    //       className="img-fluid"
                    //       alt="Play Store"
                    //     />
                    //   </Link>
                    // </div>
                  )}
                </Card>
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}
